import React from "react"
import { graphql } from 'gatsby'
import { Row, Col, Container} from "react-bootstrap"
import Layout from "../components/layout"
import SEO from "../components/seo"
import HeroImage from "../components/heroImage"
import FeatureBox from "../components/featureBox"
import Image from "gatsby-image"

const IndexPage = ({data}) => (
  <Layout pageInfo={{ pageName: "index" }}>
    <SEO title="Strona główna" description = "Dekarz Poznań. Usługi dekarskie. Krycie dachów. Rzetelne Usługi dekarskie na terenie Poznania i okolic. Na stronie znajsuje się aktualny cennik usług, oraz kosztorys on-line. Zachęcamy również do obejrzenie zdjęć zrelizowanych projektów"/>
    <HeroImage bgSrc={data.heroBgImg.childImageSharp.fluid}></HeroImage>
    <section id="oferta">
				<Container className="mb-5 pb-5">
        <div className="row pt-3">
						<Col lg={7}>
							<h2 className="title-section font-weight-semibold mb-0">O firmie</h2>
							<p className="small-title par-grey fw-400 fs-125 p-2">Firma świadczy usługi na terenie Poznania i okolic. Dzięki wieloletniemu doświadczeniu nasze projekty realizowane są rzetelnie, zgodnie z Państwa oczekiwaniami.<br/> Bazując na naszej wiedzy, zawsze staramy się jak najlepiej doradzić Klientowi wybór materiałów, jak i wykonanie.</p>
						</Col>
            <Col lg={5}>
              <Image className="about-img" fluid={data.about.childImageSharp.fluid} alt="O firmie"/>
						</Col>
					</div>

					<div className="row pt-5">
						<div className="col">
							<h2 className="title-section font-weight-semibold mb-0">Nasze usługi</h2>
							<p className="small-title par-grey fw-400 fs-125">Specjalizujemy się w dostarczaniu Klientom kompleksowych usług</p>
						</div>
					</div>
					<Row className="mt-5 mb-5">
						<Col lg={4}>
              <FeatureBox title="Roboty ciesielskie, stolarskie" img="/icons/construction.svg">
              <ul>
                <li>Konstrukcje dachowe</li>
                <li>Deskowanie, łacenie</li>
                <li>Podbitki, nadbitki</li>
              </ul>
              </FeatureBox>
						</Col>
						<Col lg={4}>
              <FeatureBox title="Pokrycia dachowe" img="/icons/roofs.svg">
              <ul>
                <li>Dachówka</li>
                <li>Blachodachówka</li>
                <li>Papa termozgrzewalna</li>
                <li>Gont</li>
              </ul>
              </FeatureBox>
						</Col>
            <Col lg={4}>
              <FeatureBox title="Dodatkowe usługi dekarskie" img="/icons/chimney.svg">
              <ul>
                <li>Systemy rynnowe</li>
                <li>Obróbki kominów</li>
                <li>Wstawianie okien dachowych</li>
              </ul>
              </FeatureBox>
						</Col>
					</Row>
					<Row className="mt-lg-4">
					
          <Col lg={4}>
            <FeatureBox title="Serwis dachów" img="/icons/toolbox.svg">
              <ul>
                <li>Sprawdzenie stanu technicznego dachu</li>
                <li>Naprawa uszkodzeń</li>
                <li>Udrożnienie i oczyszczenie rynien i rur spustowych</li>
              </ul>
            </FeatureBox>
          </Col>
						<Col lg={4}>
              <FeatureBox title="Renowacja pokryć dachowych" img="/icons/pressure-washer.svg">
              <ul>
                <li>Czyszczenie dachów</li>
                <li>Malowanie dachów</li>
              </ul>
              </FeatureBox>
						</Col>

            <Col lg={4}>
              <FeatureBox title="Altany" img="/icons/altana.svg">
              <ul>
                <li>Altany ogrodowe</li>
                <li>Domki narzędziowe</li>
                <li>Drewutnie</li>
                <li>Wiaty samochodowe</li>
              </ul>
              </FeatureBox>
						</Col>
					</Row>
				</Container>
			</section>
  </Layout>
)

// export const images = graphql`
//   {
//     heroBgImg: file(name: {eq: "roof_home"}) {
//       childImageSharp {
//         fluid {
//           ...GatsbyImageSharpFluid_tracedSVG
//         }
//       }
//     }
//   }`

  export const images = graphql`
  {
    heroBgImg: file(name: {eq: "roof"}) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    about: file(name: {eq: "o-firmie"}) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }`

export default IndexPage
