import styled from "styled-components"
import React from "react"

const HeroImage = ({ bgSrc }) => {
    return (
        <HeroImg image={bgSrc.src} alt="usługi dekarskie">
            <HeroInner>
                <HeroTextH2>USŁUGI DEKARSKIE</HeroTextH2>
                <Subtitle>Eksperci w swoim fachu</Subtitle>
                {/* <HeroTextButton>Skontaktuj się z nami</HeroTextButton> */}
            </HeroInner>
        </HeroImg>
    )
}

const HeroImg = styled.div`
    /* Sizing */
    width: 100%;
    height: 50vh;
    
    /* Flexbox stuff */
    display: flex;
    justify-content: center;
    align-items: center;
    
    /* Text styles */
    text-align: center;
    color: white;
    font-weight: bold;
    /* Background styles */
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${({ image }) => image});
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    /* filter: blur(5px); */
  `

const Subtitle = styled.div`
    font-size: 2em;
    letter-spacing: 0.5rem;
    font-weight: 200;
    padding-left: 15px;
    padding-right: 15px;

    @media (max-width: 550px) {
            letter-spacing: 0.2rem;
           
}
`
const HeroInner = styled.div`
`
const HeroTextH2 = styled.h1`
    /* Text styles */
    font-size: 4em;
    font-weight: 600;
    /* Margins */
    margin-top: 0;
    margin-bottom: 0.5em;
    letter-spacing: 1.2rem;

    @media (max-width: 550px) {
            letter-spacing: 0.7rem;
            font-size: 2em!important;
        
}
  `

// const HeroTextButton = styled.div`
//    /* Positioning and sizing */
//     display: block;
//     /* width: 200px; */
    
//     /* Padding and margins */
//     padding: 0.5em;
//     margin-top: 50px;
//     margin-left: auto;
//     margin-right: auto;
    
//     /* Text styles */
//     color: white;
//     text-decoration: none;
//     font-size: 1.5em;
    
//     /* Border styles */
//     border: 3px solid white;
//     border-radius: 20px;
    
//     /* Background styles */
//     background-color: #eeb313;
// `
export default HeroImage
