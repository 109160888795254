import styled from "styled-components"
import React from "react"

const FeatureBox = ({ title, img, children }) => {
    return (
        <FeatureBoxStyle className="feature-box feature-box-style-2 appear-animation animated fadeInUp appear-animation-visible" data-appear-animation="fadeInUp" data-appear-animation-delay="300">
            <FeatureBoxIcon className="mw-4-7">
                <img src={img} alt={title} className="img-fluid"/>
            </FeatureBoxIcon>
            <FeatureBoxInfo>
                <FeatureH5 >{title}</FeatureH5>
                <Paragraph>{children}</Paragraph>
            </FeatureBoxInfo>	
    </FeatureBoxStyle>
    )
}

const FeatureBoxStyle = styled.div`
    display: flex;
`

const FeatureBoxIcon = styled.div`
   width: 50px;
   height: 50px;
`

const FeatureBoxInfo = styled.div`
    flex: 1 1 100%;
    padding-left: 15px;
`
const Paragraph = styled.div`
    color: #777;
    line-height: 24px;
    margin: 0 0 20px;
`

const FeatureH5 = styled.h5`
    color: #eeb313;
    font-weight: 600 !important;
    letter-spacing: 1px;
    line-height: 27px;
    margin: 0 0 20px 0;
    text-transform: uppercase;
    padding-left: 10px;

`
export default FeatureBox
